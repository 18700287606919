import Page from '@/components/templates/Page';
import { getEntry } from '@/lib/api.cms';
import Layout from '@/components/layout';
import { META_ROBOTS_DEFAULT } from '@/lib/constants';
import { getLocales } from '@/lib/api.locale';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import { IPage } from '@/types/index';
import { getSettings } from '@/utils/Settings';
import { getSettingsTheme } from '@/utils/SettingsTheme';
import { IMirtaServerProps } from '@/types/IMirtaServerProps';
import { ICustomPage } from '@/types/contentful/contentful';
import { DEFAULT_CURRENCY } from '@/utils/Currency';

const Page404: React.FC<IPage> = ({
  preview,
  data,
  locales,
  locale,
  currency,
}) => {
  return (
    <Layout
      layout={data.layout}
      preview={preview}
      locales={locales}
      locale={locale}
      currency={currency}>
      <Page content={data.content} />
    </Layout>
  );
};

export default Page404;

export const getStaticProps: GetStaticProps<
  // Nexts wants the 404 page to be static
  IMirtaServerProps
> = async context => {
  const { locale = 'en' } = context;
  const preview = context.preview ? context.preview : false;
  const locales = getLocales();
  const currency = DEFAULT_CURRENCY;
  const page = (await getEntry(
    'customPage',
    'slug',
    '404',
    locale,
    preview
  )) as ICustomPage;

  if (!page || !page.fields) {
    return <></>;
  }

  const canonicalUrl = {
    canonical: `${process.env.BASE_DOMAIN_URL}/${
      locale !== context.defaultLocale ? locale + '/404' : '404'
    }`,
  };

  const robots = {
    robots: page.fields.meta?.fields?.robots
      ? page.fields.meta.fields.robots
      : META_ROBOTS_DEFAULT,
  };

  const data = {
    layout: {
      header: page.fields.layout?.fields?.header?.fields || null,
      footer: page.fields.layout?.fields?.footer?.fields || null,
      meta: { ...page.fields.meta?.fields, ...canonicalUrl, ...robots },
    },
    content: [
      ...(page.fields.content || []),
      ...(page.fields.layout?.fields?.footer?.fields?.section || []),
    ],
  };

  const settings = getSettings(data);
  const settingsTheme = getSettingsTheme();

  return {
    props: {
      data,
      preview,
      locales: locales.items,
      locale,
      settings,
      settingsTheme,
      currency,
      ...(await serverSideTranslations(locale, ['common', 'account'])),
    },
    revalidate: 1, // In seconds
  };
};
